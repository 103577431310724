<template>
  <b-input-group size="lg" class="shadow rounded-pill pr-3 bg-white">
    <b-input-group-prepend class="w-100">
      <div class="row no-gutters w-100">
        <div class="col-auto">
          <!-- button visible on md and up -->
          <button
            @click="serchattraction()"
            type="submit"
            class="btn btn-lg text-white rounded-pill py-4 px-5 d-none d-md-block"
            style="background-color: #34d0b6; border-color: #34d0b6"
          >
            חפשו טיול
          </button>
          <!-- button visible on md and up -->
          <!-- button visible only on sm -->
          <button
            @click="serchattraction()"
            class="btn btn-lg rounded-pill d-flex d-md-none"
            style="
              background-color: #34d0b6;
              border-color: #34d0b6;
              padding: 1rem;
            "
          >
            <b-icon icon="search" variant="white" scale="1"></b-icon>
          </button>
          <!-- button visible only on sm -->
        </div>
        <div class="col">
          <div
            class="d-flex justify-content-around align-items-center px-2 h-100"
          >
            <!-- <b-form-group class="mb-0 text-right">
              <div class="text-right">
                <label for="select-1" class="mb-0">{{
                  selectsOptions.first.label.text
                }}</label>
                <b-form-select
                  id="select-1"
                  class="my-border rounded-0 mt-n2 text-right"
                  :options="selectsOptions.first.options"
                  v-model="firstOption"
                >
                </b-form-select>
              </div>
            </b-form-group> -->

            <!-- <b-form-group class="mx-1 mb-0 text-right dropdown-width">
              <div class="text-right">
                <label for="select-1" class="mb-0">{{
                  selectsOptions.second.label.text
                }}</label>
                <b-dropdown
                  variant="dark-oultine"
                  class="mr-2 form-control p-0 mt-n2 bg-transparent rounded-0 my-border"
                  size="lg"
                  toggle-class="my-class"
                  right
                  block
                  no-caret
                >
                  <template #button-content>
                    <div class="d-flex align-items-center">
                      <span class="expand-arrow ml-n2 mr-2"></span>
                      <small class="ml-auto small"
                        >2 מבוגרים 2 ילדים</small
                      >
                    </div>
                  </template>
                  <b-dropdown-form style="width: 280px; text-align: right">
                    <b-row class="align-items-center mb-2">
                      <b-col>
                        <b-form-spinbutton
                          v-model="value1"
                          min="1"
                        ></b-form-spinbutton>
                      </b-col>
                      <b-col>מבוגרים</b-col>
                    </b-row>
                    <b-row class="align-items-center mb-2">
                      <b-col>
                        <b-form-spinbutton
                          v-model="value2"
                          min="0"
                        ></b-form-spinbutton>
                      </b-col>
                      <b-col>נערים</b-col>
                    </b-row>
                    <b-row class="align-items-center">
                      <b-col>
                        <b-form-spinbutton
                          v-model="value3"
                          min="0"
                        ></b-form-spinbutton>
                      </b-col>
                      <b-col>ילדים</b-col>
                    </b-row>
                  </b-dropdown-form>
                </b-dropdown>
              </div>
            </b-form-group>
 -->

            <div class="btn-toolbar" >
              <div class="text-right btn-toolbar mr-2">
                <label for="select-1" class="mb-0">{{
                    selectsOptions.third.label.text
                  }}
                  </label>
                    <gmap-autocomplete
                    @place_changed="updateCurrentPlace"
                    class="light-bg border-1 text-right"
                    placeholder="אוסטריה"
                  >
                </gmap-autocomplete>
              </div>
              <b-icon-search class=" h3 mr-2" ></b-icon-search>
            </div>
          </div>
        </div>
      </div>
    </b-input-group-prepend>
  </b-input-group>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      value1: 2,
      value2: 0,
      value3: 0,

      firstOption: "3",
      selectsOptions: {
        first: {
          label: { text: "ימים" },
          options: [
            { value: "1", text: "1" },
            { value: "2", text: "2" },
            { value: "3", text: "3" },
            { value: "4", text: "4" },
            { value: "5", text: "5" },
            { value: "6", text: "6" },
            { value: "7", text: "7" },
            { value: "14", text: "14" },
            { value: "21", text: "21" },
          ],
        },
        second: {
          label: { text: "אנשים" },
          options: ["1", "2", "3", "4", "5", "6", "7", "14", "21"],
        },
        third: {
          label: { text: "" },
        },
      },
    };
  },
   methods: {
    ...mapActions([  'fetchGlobalRides' ]),
    updateCurrentPlace(place) {
      if (place.geometry) {
        this.gMapPlace = place;
        const filters = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          physicalDisabilities: false
        };
        this.$store.commit("setGlobalRidesFilters", filters);
      }
    },
    serchattraction() {
      const path = '/search_ride'
      if ( this.$route.path !== path ) this.$router.push( path )
      this.fetchGlobalRides();

    },
  },
};
</script>
<style>
.my-border {
  border: none;
  border-bottom: 1px solid #929fba !important;
}
</style>